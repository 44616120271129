<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">User</h3>
            </div>
            <div class="card-body">
              <div class="row1">
                <form>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          alternative=""
                          label="Username"
                          placeholder="Username"
                          input-classes="form-control-alternative"
                          v-model="model.username"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <base-input
                          alternative=""
                          label="Password"
                          placeholder="Password"
                          input-classes="form-control-alternative"
                          v-model="model.password"
                        />
                      </div>
                      <div class="col-lg-6">
                        <base-input
                          alternative=""
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          input-classes="form-control-alternative"
                          v-model="model.confirmpassword"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <base-input
                          alternative=""
                          label="Email"
                          placeholder="Email"
                          type="email"
                          input-classes="form-control-alternative"
                          v-model="model.email"
                        />
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                      <label class="custom-control-label" for="customCheck1"
                        >Active?</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-5"></div>
                    <div class="col-md-4">
                      <button
                        type="button"
                        class="btn btn-default"
                        v-on:click="create()"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "add_member",
  data() {
    return {
      model: {
        username: "",
        email: "",
        confirmpassword: "",
        password: "",
      },
    };
  },
  methods: {
    onCopy(el) {
      var test = document.getElementById(el);
      test.select();
      document.execCommand("copy");
    },
  },
};
</script>

<style></style>
